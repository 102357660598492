<template>
    <v-flex xs12>
		<v-container>
			<v-layout row wrap>
				<v-flex xs12>
					<h2>Adjunta fotos de tu negocio <span style="font-weight:normal">(opcional)</span></h2>
					<div v-if="user.via_id == 2">
						<p>Para aumentar tus posibilidades de éxito en la evaluación, se recomienda incluir 3 fotografías que contengan lo siguiente:</p>
						<ul style="list-style: auto;font-weight: 700;">
							<li>Una selfie con tu negocio.</li>
							<li>Insumos o mercadería.</li>
							<li>Maquinas o herramientas, si posees.</li>
						</ul>
					</div>
					<div v-else>
						<p>Para aumentar tus posibilidades de éxito en la evaluación, se recomienda incluir al menos una foto de tu instalación comercial.</p>
					</div>
				</v-flex>
			</v-layout>
		</v-container>
		<input
			type="file"
			multiple="multiple"
			accept="image/png, image/gif, image/jpeg"
			style="display: none"
			ref="fileInput"
			@change="onFilePicked">
		<v-container>
			<div style="border: 0.124rem solid #cecece !important; border-radius:25px;padding: 20px 0px;">
				<v-layout row wrap style="justify-content: center;">
					<v-flex xs12 md4 lg4 v-if="(imagenesPrev.length < 3)">
						<div @click='onPickFile' class='rc-uplImgFrame'>
							<div class='rc-uplBack'>
								<div>
									<span class="material-icons-outlined" style="font-size: 2.5rem;">
										add_photo_alternate
									</span>
									<p>Subir archivo</p>
								</div>
							</div>
						</div>
					</v-flex>
					<v-flex xs12 md4 lg4 v-for="(foto, index) in imagenesPrev" :key="index">
						<div class='rc-uplImgFrame'>
							<div class='rc-uplPic' v-bind:style="{
										'background-image': `url(${foto})`, 
										'background-size': 'cover',
										'background-position':'center'}"
										@mouseover="mouseOver(index)"
										@mouseleave="mouseOut(index)"
							>
								<span v-if="!hovers[`hover${index}`]" class="material-icons rc-uplImg-ok">
									check_circle
								</span>
								<span v-else class="material-icons rc-uplImg-del" @click="delImg(index)">
									delete
								</span>		
							</div>
						</div>
					</v-flex>
				</v-layout>
			</div>
		</v-container>
	</v-flex>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	import { EventBus } from "@/main";
	import { mapState } from "vuex";

	@Component({
		props:{
			beforeImages : Array
		},
		computed: {
			...mapState(["user"]),
		},
  		data() {
			return {
				hover: [],
				hovers: {
					hover0 : false,
					hover1 : false,
					hover2 : false,
				},
				imagenesPrev : [],
				imgCount : 0,
				imagenes : [],
			}
  		},
		methods: {
			onPickFile () {
				this.$refs.fileInput.click()
			},
			onFilePicked (event) {
				const maxBytes = 10000000;
				const files = event.target.files
				if ( files.length <= ( 3 - this.$data.imagenes.length ) ){ // Comprobar validacion
					this.$data.imagenes.length > 0 ? this.$data.imagenes = [...this.$data.imagenes, ...files] : this.$data.imagenes = [...files]
					Array.from(files).map((pic) => {
						if(pic.size > maxBytes){
							this.$data.imagenes = this.$data.imagenes.filter((item) => item.name !== pic.name);
							EventBus.$emit("snack-error", `La foto ${pic.name} supera los 10mb`);
						}else{
							let fr = new FileReader();
							fr.readAsDataURL(pic)
							fr.addEventListener('load', () => {
								this.$data.imagenesPrev = [...this.$data.imagenesPrev, fr.result];
								this.$data.hover = [...this.$data.hover, false];
							});
						}
						
					});
					this.$emit('imagenes',this.$data.imagenes); // envio data imagenes al padre
				}else{
					EventBus.$emit("snack-error", "Supera el limite de 3 fotos");
				}
			},
			mouseOver(index){
				this.$data.hovers[`hover${index}`] = true;
			},
			mouseOut(index){
				this.$data.hovers[`hover${index}`] = false;
			},
			delImg(index){
				this.$data.imagenesPrev.splice(index, 1);
				this.$data.imagenes.splice(index, 1);
				this.$emit('imagenes',this.$data.imagenes); // envio data imagenes al padre
				this.$data.hovers[`hover${index}`] = false;
			}
 		},
		watch: {
			beforeImages: function(val, oldVal){
				if(val.length > 0){
					this.$data.imagenesPrev = val;
				}
			}
		}
	})
	export default class UploadImg extends Vue {}
</script>

<style lang="scss" scoped>
	/**
	rc-uplImg pendiente para borrar
	*/
	.rc-uplImg {
		width: 150px;
		height: 150px;
		background: $rc-prim-sunny;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: auto;
		cursor:pointer;
		
		div{
			text-align:center;color:white
		}
		.rc-uplImg-ok{
			color: $rc-sec-hgl1;
			font-size: 2.5rem;
		}
		.rc-uplImg-del{
			color: $rc-sec-hgl2;
			font-size: 2.5rem;
		}
		&:hover{
			transform: scale(1.2);
		}
	}

	.rc-uplImgFrame{
		width: 150px;
		height: 150px;
		border-radius: 25px;
		overflow: hidden;
		margin: auto;
		cursor:pointer;
		.rc-uplPic{
			width: 100%;
  			height: 100%;
			display: flex;
			justify-content: center;
    		align-items: center;
			-webkit-transition: all .5s;
			-moz-transition: all .5s;
			-o-transition: all .5s;
			transition: all .5s;
			.rc-uplImg-ok{
				color: $rc-sec-hgl1;
				font-size: 2.5rem;
				transform: scale(1);
			}
			.rc-uplImg-del{
				color: $rc-sec-hgl2;
				font-size: 2.5rem;
				transform: scale(1);
			}
			&:hover{
				transform: scale(1.2);
			}
		}
		.rc-uplBack{
			width: 100%;
  			height: 100%;
			display: flex;
			justify-content: center;
    		align-items: center;
			-webkit-transition: all .5s;
			-moz-transition: all .5s;
			-o-transition: all .5s;
			transition: all .5s;
			background: $rc-prim-sunny;
			text-align:center;
			color:white;
			.rc-uplImg-ok{
				color: $rc-sec-hgl1;
				font-size: 2.5rem;
				transform: scale(1);
			}
			.rc-uplImg-del{
				color: $rc-sec-hgl2;
				font-size: 2.5rem;
				transform: scale(1);
			}
			&:hover{
				transform: scale(1.2);
			}
		}
	}

	@each $breakpoint in $breakpoints_screen_text{
		@include breakpoint_media_queries($breakpoint){
			@if $breakpoint == "x-large"{
			}@else if $breakpoint == "large"{
			}@else if $breakpoint == "medium-to-large"{
			}@else if $breakpoint == "small-to-medium"{
				.rc-uplImgFrame{
					margin-top: 10px;
					margin-bottom: 10px;
				}
			}@else if $breakpoint == "small"{
				.rc-uplImgFrame{
					margin-top: 10px;
					margin-bottom: 10px;
				}
			}
		}
	}  
</style>
